define("discourse/plugins/hosted-site/discourse/templates/manage-account", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="admin-container admin-config-page__main-area">
    <DPageHeader
      @titleLabel="Manage Account"
      @descriptionLabel={{i18n "admin.config.manage_account.header_description"}}
      @hideTabs={{true}}
    >
      <:breadcrumbs>
        <DBreadcrumbsItem @path="/admin" @label={{i18n "admin_title"}} />
        <DBreadcrumbsItem @path="/admin/manage-account" @label="Manage Account" />
      </:breadcrumbs>
    </DPageHeader>
    <div class="manage-account-page">
      <ManageAccount />
    </div>
  </div>
  */
  {
    "id": "SWK9Yh04",
    "block": "[[[10,0],[14,0,\"admin-container admin-config-page__main-area\"],[12],[1,\"\\n  \"],[8,[39,1],null,[[\"@titleLabel\",\"@descriptionLabel\",\"@hideTabs\"],[\"Manage Account\",[28,[37,2],[\"admin.config.manage_account.header_description\"],null],true]],[[\"breadcrumbs\"],[[[[1,\"\\n      \"],[8,[39,4],null,[[\"@path\",\"@label\"],[\"/admin\",[28,[37,2],[\"admin_title\"],null]]],null],[1,\"\\n      \"],[8,[39,4],null,[[\"@path\",\"@label\"],[\"/admin/manage-account\",\"Manage Account\"]],null],[1,\"\\n    \"]],[]]]]],[1,\"\\n  \"],[10,0],[14,0,\"manage-account-page\"],[12],[1,\"\\n    \"],[8,[39,5],null,null,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[],false,[\"div\",\"d-page-header\",\"i18n\",\":breadcrumbs\",\"d-breadcrumbs-item\",\"manage-account\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/templates/manage-account.hbs",
    "isStrictMode": false
  });
});