define("discourse/plugins/hosted-site/discourse/templates/connectors/admin-dashboard-top/hosted-site", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <ManageAccountPreview @outletName="admin-dashboard-top" />
  */
  {
    "id": "goaU7x3D",
    "block": "[[[8,[39,0],null,[[\"@outletName\"],[\"admin-dashboard-top\"]],null]],[],false,[\"manage-account-preview\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/templates/connectors/admin-dashboard-top/hosted-site.hbs",
    "isStrictMode": false
  });
});