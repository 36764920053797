define("discourse/plugins/hosted-site/discourse/components/manage-account-preview", ["exports", "@glimmer/component", "@ember/object", "@ember/render-modifiers/modifiers/did-insert", "@ember/service", "discourse/plugins/hosted-site/discourse/components/hosted-dashboard", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _didInsert, _service, _hostedDashboard, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // TODO: Remove this component when the admin-dashboard-after-header plugin outlet lands in stable.
  // The component was added to provide backwards compatibility for stable which still uses the
  // admin-dashboard-top plugin outlet.
  class ManageAccountPreview extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "hostedDashboardState", [_service.service]))();
    #hostedDashboardState = (() => (dt7948.i(this, "hostedDashboardState"), void 0))();
    // Only the after-header instance sets the rendered flag when inserted.
    handleDidInsert() {
      if (this.args.outletName === "admin-dashboard-after-header") {
        if (!this.hostedDashboardState.rendered) {
          this.hostedDashboardState.rendered = true;
        }
      }
    }
    // Always render for after-header; for top, render only if not rendered.
    static #_2 = (() => dt7948.n(this.prototype, "handleDidInsert", [_object.action]))();
    get shouldRender() {
      return this.args.outletName === "admin-dashboard-after-header" || !this.hostedDashboardState.rendered;
    }
    static #_3 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div {{didInsert this.handleDidInsert}}>
          {{#if this.shouldRender}}
            <HostedDashboard />
          {{/if}}
        </div>
      
    */
    {
      "id": "rjePr3oh",
      "block": "[[[1,\"\\n    \"],[11,0],[4,[32,0],[[30,0,[\"handleDidInsert\"]]],null],[12],[1,\"\\n\"],[41,[30,0,[\"shouldRender\"]],[[[1,\"        \"],[8,[32,1],null,null,null],[1,\"\\n\"]],[]],null],[1,\"    \"],[13],[1,\"\\n  \"]],[],false,[\"if\"]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/hosted-site/discourse/components/manage-account-preview.js",
      "scope": () => [_didInsert.default, _hostedDashboard.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = ManageAccountPreview;
});