define("discourse/plugins/hosted-site/discourse/services/hosted-dashboard-state", ["exports", "@glimmer/tracking", "@ember/service"], function (_exports, _tracking, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class HostedDashboardStateService extends _service.default {
    static #_ = (() => dt7948.g(this.prototype, "rendered", [_tracking.tracked], function () {
      return false;
    }))();
    #rendered = (() => (dt7948.i(this, "rendered"), void 0))();
  }
  _exports.default = HostedDashboardStateService;
});